<div *ngIf="route.url != '/anjana/login'">
  <header>
    <nav>
      <div class="nav-content">
        <div class="positionIconHeader">
          <ul class="nav-list">
            <li>
              <div class="select-wrapper">
                <form [formGroup]="selectBranch">
                  <label>Branch</label>
                  <select class="formControl" formControlName="branch" (change)="changeBranch($event)">
                    <option selected disabled>Choose Branch</option>
                    <option *ngFor="let branch of branches" [value]="branch">
                      {{ branch }}
                    </option>
                  </select>
                </form>
              </div>
            </li>
            <li [routerLink]="['/anjana/profile']">Profile</li>
          </ul>
        </div>
        <button class="logout-btn" (click)="logout()">Logout</button>
      </div>
    </nav>
  </header>
</div>