import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectService } from '../services/projects.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  selectBranch: FormGroup
  branches = ['Branch 1','Branch 2'];
  branch : any;
  isAuthenticated: boolean = false;
  branchData: any;
  constructor(private authService: AuthService, public route: Router, private fb: FormBuilder){}

  ngOnInit(){
    this.branch = localStorage.getItem('Branch');
    
    this.authService.authStatus.subscribe(status => this.isAuthenticated = status);
    this.selectBranch = this.fb.group({
      branch : [this.branch]
    })
  }

  changeBranch(event: any){
    this.branch = event.target.value;
    localStorage.setItem('Branch', this.branch);
    window.location.reload();
  }

  logout(){
    this.authService.logout();
    localStorage.removeItem('AUTH_TOKEN');
    this.route.navigate(['/anjana']);
  }
}
