
<div *ngIf = "route.url != '/anjana/login'">
  <div class="menu-side-wrapper" style="overflow-y: scroll;">
    <div class="mobileMenuDiv">
      <img
        class="sideNavLogomain logoMainall"
        alt="Logo"
        src="https://www.munawelgroup.com/wp-content/uploads/2023/04/Logo-3-2x-2.png"
      />

      <ul class="mainNavMenu">
        <ng-container *ngxPermissionsOnly="['ADMIN', 'HR']">
          <li (click)="activedIndex(1)"  [ngClass]="{ active: activeIndex == 1 }">
            <a [routerLink]="['/anjana/dashboard']" routerLinkActive="router-link-active">
              <p>Dashboard</p>
            </a>
          </li>
        </ng-container>
        

        <li (click)="toggleProjectManagement()" *ngxPermissionsOnly="['ADMIN','EMPLOYEE']" [ngClass]="{ active: isProjectManagementOpen }">
            <a>
              <p>Project Management</p>
            </a>
            <ul class="subMenu projectManagement" *ngIf="isProjectManagementOpen">
              <li (click)="activedIndex(2)" [ngClass]="{ active: activeIndex == 2 }">
                <a [routerLink]="['/anjana/projects']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Projects</p>
                </a>
              </li>
              <li (click)="activedIndex(2)" [ngClass]="{ active: activeIndex == 2 }">
                <a [routerLink]="['/anjana/quotation']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Quotation</p>
                </a>
              </li>
              <li (click)="activedIndex(3)" [ngClass]="{ active: activeIndex == 3 }">
                <a [routerLink]="['/anjana/invoice']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Invoice</p>
                </a>
              </li>
              <li (click)="activedIndex(4)" [ngClass]="{ active: activeIndex == 4 }">
                <a [routerLink]="['/anjana/work-order']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Work Order</p>
                </a>
              </li>
            </ul>
          </li>

          <li (click)="togglePurchaseManagement()" *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: isPurchaseManagementOpen }">
            <a>
              <p>Purchase</p>
            </a>
            <ul class="subMenu purchaseManagement" *ngIf="isPurchaseManagementOpen">
              <li (click)="activedIndex(5)" [ngClass]="{ active: activeIndex == 5 }">
                <a [routerLink]="['/anjana/purchase-order']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Purchase Order</p>
                </a>
              </li>
              <li (click)="activedIndex(6)" [ngClass]="{ active: activeIndex == 6 }">
                <a [routerLink]="['/anjana/grn']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">GRN</p>
                </a>
              </li>
              <li (click)="activedIndex(7)" [ngClass]="{ active: activeIndex == 7 }">
                <a [routerLink]="['/anjana/vendors']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Vendors</p>
                </a>
              </li>
              <li (click)="activedIndex(8)" [ngClass]="{ active: activeIndex == 8 }">
                <a [routerLink]="['/anjana/credit-order']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Credit Note</p>
                </a>
              </li>
            </ul>
          </li>

        <li (click)="activedIndex(9)" *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: activeIndex == 9 }">
          <a [routerLink]="['/anjana/approvals']" routerLinkActive="router-link-active">
            <p>Approvals</p>
          </a>
        </li>

        <li (click)="activedIndex(10)" *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: activeIndex == 10 }">
          <a [routerLink]="['/anjana/inventory']" routerLinkActive="router-link-active">
            <p>Inventory</p>
          </a>
        </li>

        <li (click)="toggleAccountsManagement()" *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: isAccountsManagementOpen }">
            <a>
              <p>Accounts</p>
            </a>
            <ul class="subMenu accountsManagement" *ngIf="isAccountsManagementOpen">
              <li (click)="activedIndex(11)" [ngClass]="{ active: activeIndex == 11 }">
                <a [routerLink]="['/anjana/currency-adjustments']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Currency Adjustments</p>
                </a>
              </li>
              <li (click)="activedIndex(12)" [ngClass]="{ active: activeIndex == 12 }">
                <a [routerLink]="['/anjana/manual-journel']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Manual Journel</p>
                </a>
              </li>
              <li (click)="activedIndex(19)" [ngClass]="{ active: activeIndex == 19 }">
                <a [routerLink]="['/anjana/debit-note']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Debit Note</p>
                </a>
              </li>
            </ul>
          </li>

          <li (click)="toggleHRM()" *ngxPermissionsOnly="['ADMIN','HR']" [ngClass]="{ active: isHRMOpen }">
            <a>
              <p>HRM</p>
            </a>
            <ul class="subMenu HRMManagement" *ngIf="isHRMOpen">
              <li (click)="activedIndex(13)" [ngClass]="{ active: activeIndex == 13 }">
                <a [routerLink]="['/anjana/all-branches']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">All Branches</p>
                </a>
              </li>
              <li (click)="activedIndex(14)" [ngClass]="{ active: activeIndex == 14 }">
                <a [routerLink]="['/anjana/hrms/all-employees']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">All Employees</p>
                </a>
              </li>
              <li (click)="activedIndex(15)" [ngClass]="{ active: activeIndex == 15 }">
                <a [routerLink]="['/anjana/hrms/attendance']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Attendance</p>
                </a>
              </li>
              <li (click)="activedIndex(22)" [ngClass]="{ active: activeIndex == 22 }">
                <a [routerLink]="['/anjana/hrms/leave']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Leave</p>
                </a>
              </li>
              <li (click)="activedIndex(16)" [ngClass]="{ active: activeIndex == 16 }">
                <a [routerLink]="['/anjana/hrms/payroll']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Payroll</p>
                </a>
              </li>
              <li (click)="activedIndex(21)" [ngClass]="{ active: activeIndex == 21 }">
                <a [routerLink]="['/anjana/hrms/vacation']" routerLinkActive="router-link-active">
                  <p class="d-flex justify-content-space-evenly align-items-center">Vacation</p>
                </a>
              </li>
            </ul>
          </li>

        <li (click)="activedIndex(17)" *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: activeIndex == 17 }">
          <a [routerLink]="['/anjana/reports']" routerLinkActive="router-link-active">
            <p>Reports</p>
          </a>
        </li>

        <li (click)="toggleSettings()"  *ngxPermissionsOnly="['ADMIN']" [ngClass]="{ active: isSettingsOpen }">
          <a>
            <p>Settings</p>
          </a>
          <ul class="subMenu SettingsManagement" *ngIf="isSettingsOpen">
            <li (click)="activedIndex(18)" [ngClass]="{ active: activeIndex == 18 }">
              <a [routerLink]="['/anjana/forgot-password']" routerLinkActive="router-link-active">
                <p class="d-flex justify-content-space-evenly align-items-center">Forgot Password</p>
              </a>
            </li>
            <li (click)="activedIndex(20)" [ngClass]="{ active: activeIndex == 20 }">
              <a [routerLink]="['/anjana/assign-roles']" routerLinkActive="router-link-active">
                <p class="d-flex justify-content-space-evenly align-items-center">Assign Role</p>
              </a>
            </li>
            </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
