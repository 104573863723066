import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-side-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule
  ],
  providers:[NgxPermissionsService],
  templateUrl: './side-navbar.component.html',
  styleUrl: './side-navbar.component.scss'
})
export class SideNavbarComponent {
  activeIndex: any;
  typeArray: string[] = [];
  isProjectManagementOpen: boolean = false;
  isPurchaseManagementOpen: boolean = false;
  isAccountsManagementOpen: boolean = false;
  isHRMOpen: boolean = false;
  isSettingsOpen: boolean = false;
  permissionsLoaded: boolean = false;

  constructor(
    private permissionService : NgxPermissionsService,
    public authService:AuthService,
    public route: Router
    ){}

    ngOnInit(){
      const role = localStorage.getItem('ROLE');
      if (role) {
        this.permissionService.loadPermissions([role]);
      }
    }
    
  toggleProjectManagement(){this.isProjectManagementOpen = !this.isProjectManagementOpen;}
  togglePurchaseManagement(){this.isPurchaseManagementOpen = !this.isPurchaseManagementOpen;}
  toggleAccountsManagement(){this.isAccountsManagementOpen = !this.isAccountsManagementOpen;}
  toggleHRM(){this.isHRMOpen = !this.isHRMOpen;}
  toggleSettings(){this.isSettingsOpen = !this.isSettingsOpen;}
  
  activedIndex(index:any){
    this.activeIndex = index;
  }

}
