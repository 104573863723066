import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentRole: string = '';
  roleArray: any;
  constructor(
    private ngxRolesService: NgxRolesService,
    private permissionService : NgxPermissionsService,
  ) { }

  private authStatusSource = new BehaviorSubject<boolean>(false);
  authStatus = this.authStatusSource.asObservable();

  ngOnInit(){
  }
  
  login(username: string, password: string): boolean {
    this.authStatusSource.next(true);
    
    if (username === 'admin' && password === 'admin') {
      this.currentRole = username.toUpperCase();
      const storedUsertpe = sessionStorage.getItem('PERMISSION');
      const usertype: any = storedUsertpe ? JSON.parse(storedUsertpe) : {};
      const permissions = usertype[this.currentRole]?.map((permissionId : any) => `${permissionId}`) || [];
      this.ngxRolesService.addRole(this.currentRole, permissions);
      this.permissionService.loadPermissions(permissions);
      localStorage.setItem('ROLE',this.currentRole);
      localStorage.setItem('USERTYPE','FULL_ACCESS');
      return true;

    } else if (username === 'hr' && password === 'hr') {
      this.currentRole = username.toUpperCase();
      const storedRoles = sessionStorage.getItem('PERMISSION');
      const rolesMap: any = storedRoles ? storedRoles : {};
      const permissions = rolesMap[this.currentRole]?.map((permissionId : any) => `${permissionId}`) || [];
      this.ngxRolesService.addRole(this.currentRole, permissions);
      this.permissionService.loadPermissions(permissions);
      localStorage.setItem('ROLE',this.currentRole);
      localStorage.setItem('USERTYPE','PARTIAL_ACCESS');
      return true;

    } else {
      return false;
    }
  }

  getRole(): string | null {
    if(this.currentRole){
      return this.currentRole;
    }
    else {
      const storedRoles = sessionStorage.getItem('PERMISSION');
      const rolesMap: any = storedRoles ? JSON.parse(storedRoles) : {};
      return rolesMap[this.currentRole];
    }
  }

  logout(): void {
    this.authStatusSource.next(false); 
    // this.permissionService.flushPermissions();
    // this.ngxRolesService.flushRoles();
  }
}
